import './ui.module.css';

/* eslint-disable-next-line */
export interface UiProps {}

export function Ui(props: UiProps) {
    return (
        <div>
            <h1>Welcome to Ui!</h1>
        </div>
    );
}

export default Ui;
