export const galeriaImages = [
    'galeria_1.jpg',
    'galeria_10.jpg',
    'galeria_11.jpg',
    'galeria_12.JPG',
    'galeria_13.jpeg',
    'galeria_14.jpeg',
    'galeria_15.jpeg',
    'galeria_16.jpeg',
    'galeria_17.jpeg',
    'galeria_18.jpeg',
    'galeria_19.jpeg',
    'galeria_2.jpg',
    'galeria_20.jpeg',
    'galeria_21.jpeg',
    'galeria_22.jpg',
    'galeria_23.JPG',
    'galeria_24.jpg',
    'galeria_25.jpg',
    'galeria_26.jpg',
    'galeria_27.jpg',
    'galeria_28.jpg',
    'galeria_29.jpg',
    'galeria_3.jpg',
    'galeria_30.jpg',
    'galeria_31.jpg',
    'galeria_32.jpg',
    'galeria_33.jpg',
    'galeria_34.jpg',
    'galeria_35.jpg',
    'galeria_36.jpg',
    'galeria_37.jpg',
    'galeria_38.jpg',
    'galeria_39.jpg',
    'galeria_4.jpg',
    'galeria_40.jpg',
    'galeria_41.jpg',
    'galeria_42.jpg',
    'galeria_43.jpg',
    'galeria_44.jpg',
    'galeria_45.jpg',
    'galeria_46.jpg',
    'galeria_47.jpg',
    'galeria_48.jpg',
    'galeria_49.jpg',
    'galeria_5.jpg',
    'galeria_50.jpg',
    'galeria_51.jpg',
    'galeria_6.jpg',
    'galeria_7.jpg',
    'galeria_8.jpg',
    'galeria_9.jpg',
];
