import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { FrontPage } from './pages/Front';
import { Header } from './components/Header/header';
import { HistoriaPage } from './pages/Historia';
import { GaleriaPage } from './pages/Galeria';
import { GaleriaHistoricaPage } from './pages/GaleriaHistorica';
import { ContatoPage } from './pages/Contato';
import { GrupoPage } from './pages/Grupo';

export function App() {
    return (
        <Router>
            <div>
                <Header />
                <Switch>
                    <Route path="/participar">
                        <FrontPage />
                    </Route>
                    <Route path="/grupo">
                        <GrupoPage />
                    </Route>
                    <Route path="/historia">
                        <HistoriaPage />
                    </Route>{' '}
                    <Route path="/galeria">
                        <GaleriaPage />
                    </Route>
                    <Route path="/galeria-historica">
                        <GaleriaHistoricaPage />
                    </Route>
                    <Route path="/contato">
                        <ContatoPage />
                    </Route>
                    <Route path="/">
                        <FrontPage />
                    </Route>
                </Switch>
            </div>
        </Router>
    );
}
export default App;
