import { useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

export interface DisplayPdfProps {
    file: string;
}

export const DisplayPdf = ({ file }: DisplayPdfProps) => {
    const [page, setPage] = useState(1);
    const [numberOfPages, setNumberOfPages] = useState(0);
    return (
        <div className="relative border-2 border-red-500">
            <div className="z-10 absolute top-0 left-0 bg-red-500 w-full flex justify-center items-center gap-2">
                <button
                    onClick={() => setPage(page <= 2 ? 1 : page - 1)}
                    className="px-2 py-1 text-3xl bg-white font-bold text-red-500"
                >
                    -
                </button>
                <p className="text-white">Página: {page}</p>
                <button
                    onClick={() =>
                        setPage(page < numberOfPages ? page + 1 : page)
                    }
                    className="px-2 py-1 text-3xl bg-white font-bold text-red-500"
                >
                    +
                </button>
            </div>
            <Document
                file={file}
                onLoadSuccess={(d) => setNumberOfPages(d.numPages)}
            >
                <Page pageNumber={page} />
            </Document>
        </div>
    );
};
