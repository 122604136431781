import { Blockquote } from '../components/Text/Blockquote/Blockquote';
import { Alert } from '@scouts/ui';
import { Link } from 'react-router-dom';
import { DisplayPdf } from '../components/Extra/DisplayPdf';

export const HistoriaPage = () => {
    return (
        <div className="flex flex-col  items-center px-4 sm:px-12 lg:px-24 py-6">
            <div className="flex flex-col gap-3 justify-center items-center  py-8">
                <h1 className="text-center tracking-tight font-extrabold text-gray-900 text-3xl sm:text-4xl lg:text-5xl">
                    História do 123°
                </h1>
                <p className="text-2xl text-red-700 font-bold uppercase">
                    Grupo Escoteiro do Mar
                </p>
            </div>
            <div className="flex flex-col gap-4 lg:gap-6 text-lg max-w-prose mx-auto  leading-8 filter sepia-200 ">
                <img
                    className=" object-cover max-h-80"
                    src="./assets/galeria/galeria_1.jpg"
                    alt="foto de entrada"
                />
                <p>
                    O 123° Grupo Escoteiro do Mar Almirante Saldanha, foi
                    fundado em 15 de julho de 1962 pelo Comodoro Carlos Borba,
                    escoteiro número 1 do 123° GEMar.
                </p>
                <div className="flex justify-between gap-2">
                    <img
                        className=" object-cover"
                        src="./assets/historia-do-grupo/page_01_Image_0004.jpg"
                        alt="foto de entrada"
                    />
                    <div className="flex flex-col justify-center gap-2">
                        <img
                            className=" object-bottom  w-40"
                            src="./assets/historia-do-grupo/page_01_Image_0003.jpg"
                            alt="foto de entrada"
                        />
                        <img
                            className=" object-bottom  w-48"
                            src="./assets/historia-do-grupo/page_01_Image_0002.jpg"
                            alt="foto de entrada"
                        />
                    </div>
                </div>
                <div>
                    <Link to="galeria-historica">
                        <Alert
                            text="Novo: Veja as fotos históricas do grupo."
                            color="yellow"
                        />
                    </Link>
                </div>
                <p>
                    Carlos Borba – o Comodoro do CNP em 1962, era filho do
                    General Bonifácio Borba, um militar médico, que foi chefe na
                    Federação Brasileira dos Escoteiros do Mar e,
                    posteriormente, Presidente da União dos Escoteiros do
                    Brasil. Quando jovem, foi escoteiro da Tropa Ararigbóia, na
                    Tijuca, que ficava sediada na garagem da sua residência,
                    criada pelo seu pai para ele e os primos. Frequentemente
                    Gen.Borba levava Carlos para as atividades embarcadas da
                    FBEM. Tendo seguido carreira militar, retorna ao movimento
                    com a abertura do 123oGEMAR, grupo que ele fez parte até o
                    fim por mais de cinco décadas. Ele também foi Presidente da
                    Região Escoteira do Rio de Janeiro e Coordenador Nacional, e
                    Regional no RJ, dos Escoteiros do Mar, por muitos anos. Seus
                    filhos foram os primeiros lobinhos a fazer Promessa na
                    fundação do grupo.
                </p>
                <p>
                    O famoso Chefe Astroélio Ornelas Pinto Carneiro, que foi
                    Comissário responsável pela criação de novos Grupos no
                    antigo Estado da Guanabara, em suas memórias, relatou o
                    seguinte sobre o início do 123oGEMAR:
                </p>
                <Blockquote
                    text="Com experiência de fundação de grupos escoteiros comecei a
                fundar novos grupos da modalidade do mar, o que passou a ser
                minha maior paixão. A fundação do primeiro grupo escoteiro do
                mar como assistente do comissário foi no Clube Naval, onde
                comandante Borba era o presidente. Levei vários cartazes,
                panfletos e o P.O.R e fiz uma palestra sobre o movimento
                escoteiro apresentando seus princípios, estruturação, métodos e
                demais informações descritivas do maior movimento de jovens do
                planeta. Após o consenso do comandante Borba iniciamos a etapa
                de crescimento do grupo. Inicialmente preparei dois chefes e
                logo pouco tempo depois haviam duas patrulhas completas já
                aplicando o programa escoteiro do mar. Portanto, observando o
                sucesso da implantação daquele grupo levantei a ancora e zarpei
                para cumprir minha missão de fundar mais grupos escoteiros."
                />
                <div className="flex flex-col gap-1">
                    <img
                        className=" object-cover"
                        src="./assets/historia-do-grupo/page_02_Image_0002.jpg"
                        alt="foto de entrada"
                    />
                    <div className="flex flex-col border-l-2 border-red-700 pl-2">
                        <p className="font-semibold">
                            Certificado de Abertura do Grupo
                        </p>
                        <p className="text-sm">
                            Até hoje fica exposto na parede da sede escoteira.
                        </p>
                    </div>
                </div>
                <h2 className="py-4 tracking-normal font-extrabold text-gray-700 text-xl sm:text-2xl lg:text-3xl">
                    Cerimonia de Fundação
                </h2>
                <img
                    className=" object-cover max-h-72  -ml-4"
                    src="./assets/historia-do-grupo/page_03_Image_0002.jpg"
                    alt="foto de entrada"
                />
                <p>
                    Foi no dia 15 de julho de 1962 que aconteceu o cerimonial de
                    fundação do123ºGEMAR, na garagem náutica, ao lado da rampa.
                </p>
                <img
                    className=" object-cover max-h-72  -ml-2"
                    src="./assets/historia-do-grupo/page_03_Image_0003.jpg"
                    alt="foto de entrada"
                />
                <p>
                    O Almirante José dos Santos Saldanha, descendente do patrono
                    do grupo,entregou o Pavilhão Nacional para o escoteiro
                    Flávio, monitor da Patrulha Cisne.
                </p>{' '}
                <img
                    className=" object-cover max-h-72  -ml-5"
                    src="./assets/historia-do-grupo/page_04_Image_0002.jpg"
                    alt="foto de entrada"
                />
                <p>
                    O Capitão de Fragata Paulo Moreira Silva, Comandante do
                    Navio "AlmiranteSaldanha", entrega a Bandeira do Grupo para
                    o escoteiro sênior José Durval,monitor da Patrulha Almirante
                    Saldanha, durante o Cerimonial.
                </p>
                <Alert text="Em breve mais conteúdo estará disponível." />
                <DisplayPdf file="./assets/História do 123gemar - 59 anos.pdf" />
                <a
                    download="História do 123gemar - 59 anos.pdf"
                    href="./assets/História do 123gemar - 59 anos.pdf"
                >
                    <Alert text="Baixar PDF com toda a história" color="red" />
                </a>
            </div>
        </div>
    );
};
