import './alert.module.css';

/* eslint-disable-next-line */
export interface AlertProps {
    text: string;
    color?: string;
    onClick?: () => void;
}

export function Alert({ text, color, onClick }: AlertProps) {
    return (
        <div
            className={`px-4 py-2 bg-${color || 'green'}-50`}
            onClick={onClick}
        >
            <p
                className={`text-${
                    color || 'green'
                }-700 font-medium tracking-tight`}
            >
                {text}
            </p>
        </div>
    );
}

export default Alert;
