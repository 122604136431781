/* eslint-disable no-empty-pattern */

import { DisplayPdf } from '../components/Extra/DisplayPdf';

/* eslint-disable @typescript-eslint/no-empty-interface */
export interface GrupoPageProps {}

export const GrupoPage = ({}: GrupoPageProps) => {
    return (
        <div className="px-4 sm:px-12 lg:px-24 py-6">
            <div className="flex flex-col gap-2 justify-center items-center  py-8">
                <h1 className="text-center tracking-tight font-extrabold text-gray-900 text-3xl sm:text-4xl lg:text-5xl">
                    Grupo
                </h1>
                <h2 className="mb-4 tracking-tight text-lg text-red-700 font-bold uppercase">
                    Condecorados
                </h2>
                <DisplayPdf file="./assets/Condecorados 123gemar.pdf" />
                <h2 className="mb-4 tracking-tight text-lg text-red-700 font-bold uppercase">
                    Conquistas
                </h2>
                <DisplayPdf file="./assets/Conquistas 123gemar.pdf" />
                <h2 className="mb-4 tracking-tight text-lg text-red-700 font-bold uppercase">
                    Embarcações
                </h2>
                <DisplayPdf file="./assets/Nossas Embarcações 123gemar.docx.pdf" />
                <h2 className="mb-4 tracking-tight text-lg text-red-700 font-bold uppercase">
                    Parceiros
                </h2>
                <DisplayPdf file="./assets/Parceiros 123gemar.docx.pdf" />
            </div>
            <div className="flex flex-col gap-4 lg:gap-6 text-lg max-w-prose mx-auto  leading-8 filter sepia-200 "></div>
        </div>
    );
};
