//@ts-expect-error no typings
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';

export interface GalleryProps {
    images: string[];
    imagesBasePath?: string;
}
export const Gallery = ({ images, imagesBasePath }: GalleryProps) => {
    return (
        <Masonry columnsCount={3} gutter="10px">
            {images.map((image) => (
                <img
                    key={image}
                    src={`./assets/${
                        imagesBasePath ? imagesBasePath : ''
                    }${image}`}
                    alt=""
                    style={{ width: '100%', display: 'block' }}
                />
            ))}
        </Masonry>
    );
};
