/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-redundant-roles */
import { MailIcon, PhoneIcon } from '@heroicons/react/outline';
import { url } from 'inspector';

export const ContatoPage = () => {
    return (
        <div className="px-4 sm:px-12 lg:px-24 py-6">
            <div className="relative bg-indigo-800">
                <div className="absolute inset-0">
                    <img
                        className="w-full h-full object-cover"
                        src="https://images.unsplash.com/photo-1501771924607-209f42a6e7e4?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1171&q=80"
                        alt=""
                    />
                    <div
                        className="absolute inset-0 bg-red-500 mix-blend-multiply blue"
                        aria-hidden="true"
                    />
                </div>
                <div className="relative max-w-7xl mx-auto py-24 px-4 sm:pt-32 sm:pb-18 sm:px-6 lg:px-8">
                    <h1 className="text-4xl font-extrabold tracking-tight text-white sm:text-5xl lg:text-6xl">
                        Fale com a gente!
                    </h1>
                    <div className="flex flex-col gap-2 mt-16 text-xl text-gray-50 max-w-3xl">
                        <p>Clube Naval da Lagoa</p>
                        <p>
                            Av. Borges de Medeiros, 2364 Lagoa, Rio de Janeiro -
                            RJ, 22470-003
                        </p>
                    </div>
                </div>
            </div>
            <div className="bg-gray-100">
                <div className="max-w-7xl mx-auto py-16 sm:py-24">
                    <div className="relative bg-white shadow-xl">
                        <h2 className="sr-only">Fale Conosco</h2>

                        <div className="grid grid-cols-1 lg:grid-cols-3">
                            {/* Contact information */}
                            <div className="relative overflow-hidden py-10 px-6 bg-gray-800 sm:px-10 xl:p-12">
                                {/* <div className="absolute inset-0">
                                    <img
                                        className="w-full h-full object-cover"
                                        src="https://images.unsplash.com/photo-1508671318294-1afb20379417?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1074&q=80"
                                        alt=""
                                    />
                                    <div
                                        className="absolute inset-0 bg-red-600 mix-blend-multiply blue"
                                        aria-hidden="true"
                                    />
                                </div> */}
                                <h3 className="text-xl font-semibold tracking-tight text-white">
                                    Fale Conosco
                                </h3>
                                <p className="mt-6 text-base text-gray-200 max-w-3xl">
                                    Use os nossos meios de comunicação abaixo.
                                </p>
                                <dl className="mt-8 space-y-6">
                                    <dt>
                                        <span className="sr-only">
                                            Telefone
                                        </span>
                                    </dt>
                                    <dd className="flex text-base text-indigo-50">
                                        <PhoneIcon
                                            className="flex-shrink-0 w-6 h-6 text-indigo-200"
                                            aria-hidden="true"
                                        />
                                        <span className="ml-3">
                                            (21) 992696784 / (21) 996229085
                                        </span>
                                    </dd>
                                    <dt>
                                        <span className="sr-only">Email</span>
                                    </dt>
                                    <dd className="flex text-base text-indigo-50">
                                        <MailIcon
                                            className="flex-shrink-0 w-6 h-6 text-indigo-200"
                                            aria-hidden="true"
                                        />
                                        <a
                                            href="mailto:&#049;&#050;&#051;&#103;&#101;&#109;&#097;&#114;&#046;&#097;&#046;&#115;&#064;&#103;&#109;&#097;&#105;&#108;&#046;&#099;&#111;&#109;"
                                            className="ml-3"
                                        >
                                            &#049;&#050;&#051;&#103;&#101;&#109;&#097;&#114;&#046;&#097;&#046;&#115;&#064;&#103;&#109;&#097;&#105;&#108;&#046;&#099;&#111;&#109;
                                        </a>
                                    </dd>
                                </dl>
                                <ul
                                    role="list"
                                    className="mt-8 flex space-x-12"
                                >
                                    <li>
                                        <a
                                            className="text-white hover:text-red-100"
                                            href="https://www.facebook.com/123GEMarAlmiranteSaldanha/"
                                        >
                                            <span className="sr-only">
                                                Facebook
                                            </span>
                                            <svg
                                                width={24}
                                                height={24}
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="w-6 h-6"
                                                aria-hidden="true"
                                            >
                                                <path
                                                    d="M22.258 1H2.242C1.556 1 1 1.556 1 2.242v20.016c0 .686.556 1.242 1.242 1.242h10.776v-8.713h-2.932V11.39h2.932V8.887c0-2.906 1.775-4.489 4.367-4.489 1.242 0 2.31.093 2.62.134v3.037l-1.797.001c-1.41 0-1.683.67-1.683 1.653v2.168h3.362l-.438 3.396h-2.924V23.5h5.733c.686 0 1.242-.556 1.242-1.242V2.242C23.5 1.556 22.944 1 22.258 1"
                                                    fill="currentColor"
                                                />
                                            </svg>
                                        </a>
                                    </li>
                                </ul>
                            </div>

                            {/* Contact form */}
                            <div className="py-10 px-6 sm:px-10 lg:col-span-2 xl:p-12">
                                <h3 className="text-lg font-medium text-gray-900">
                                    Envie-nos uma mensagem.{' '}
                                    <span className="text-green-700">
                                        (Em breve)
                                    </span>
                                </h3>
                                <form
                                    action="#"
                                    method="POST"
                                    className="hidden mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
                                >
                                    <div>
                                        <label
                                            htmlFor="first-name"
                                            className="block text-sm font-medium text-gray-900"
                                        >
                                            Nome
                                        </label>
                                        <div className="mt-1">
                                            <input
                                                type="text"
                                                name="first-name"
                                                id="first-name"
                                                autoComplete="given-name"
                                                className="py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <label
                                            htmlFor="last-name"
                                            className="block text-sm font-medium text-gray-900"
                                        >
                                            Sobrenome
                                        </label>
                                        <div className="mt-1">
                                            <input
                                                type="text"
                                                name="last-name"
                                                id="last-name"
                                                autoComplete="family-name"
                                                className="py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <label
                                            htmlFor="email"
                                            className="block text-sm font-medium text-gray-900"
                                        >
                                            Email
                                        </label>
                                        <div className="mt-1">
                                            <input
                                                id="email"
                                                name="email"
                                                type="email"
                                                autoComplete="email"
                                                className="py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <div className="flex justify-between">
                                            <label
                                                htmlFor="phone"
                                                className="block text-sm font-medium text-gray-900"
                                            >
                                                Telefone
                                            </label>
                                            <span
                                                id="phone-optional"
                                                className="text-sm text-gray-500"
                                            >
                                                Opcional
                                            </span>
                                        </div>
                                        <div className="mt-1">
                                            <input
                                                type="text"
                                                name="phone"
                                                id="phone"
                                                autoComplete="tel"
                                                className="py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                                                aria-describedby="phone-optional"
                                            />
                                        </div>
                                    </div>
                                    <div className="sm:col-span-2">
                                        <label
                                            htmlFor="subject"
                                            className="block text-sm font-medium text-gray-900"
                                        >
                                            Assunto
                                        </label>
                                        <div className="mt-1">
                                            <input
                                                type="text"
                                                name="subject"
                                                id="subject"
                                                className="py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                                            />
                                        </div>
                                    </div>
                                    <div className="sm:col-span-2">
                                        <div className="flex justify-between">
                                            <label
                                                htmlFor="message"
                                                className="block text-sm font-medium text-gray-900"
                                            >
                                                Mensagem
                                            </label>
                                        </div>
                                        <div className="mt-1">
                                            <textarea
                                                id="message"
                                                name="message"
                                                rows={4}
                                                className="py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-indigo-500 focus:border-indigo-500 border border-gray-300 rounded-md"
                                                aria-describedby="message-max"
                                                defaultValue={''}
                                            />
                                        </div>
                                    </div>
                                    <div className="sm:col-span-2 sm:flex sm:justify-end">
                                        <button
                                            type="submit"
                                            className="mt-2 w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-red-500 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:w-auto"
                                        >
                                            Enviar
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
