export interface BlockquoteProps {
    text: string;
}
export const Blockquote = ({ text }: BlockquoteProps) => {
    return (
        <p className="pl-6 border-l-8 border-red-800 text-gray-700">
            <em>{text}</em>
        </p>
    );
};
