export const historiaImages = [
    'page_01_Image_0002.jpg',
    'page_01_Image_0003.jpg',
    'page_01_Image_0004.jpg',
    'page_02_Image_0002.jpg',
    'page_02_Image_0003.jpg',
    'page_03_Image_0002.jpg',
    'page_03_Image_0003.jpg',
    'page_04_Image_0002.jpg',
    'page_04_Image_0003.jpg',
    'page_05_Image_0002.jpg',
    'page_05_Image_0003.jpg',
    'page_05_Image_0004.jpg',
    'page_06_Image_0002.jpg',
    'page_06_Image_0003.jpg',
    'page_06_Image_0004.jpg',
    'page_07_Image_0002.jpg',
    'page_07_Image_0003.jpg',
    'page_07_Image_0004.jpg',
    'page_07_Image_0005.jpg',
    'page_08_Image_0002.jpg',
    'page_08_Image_0003.jpg',
    'page_08_Image_0004.jpg',
    'page_09_Image_0002.jpg',
    'page_09_Image_0003.jpg',
    'page_09_Image_0004.jpg',
    'page_09_Image_0005.jpg',
    'page_10_Image_0002.jpg',
    'page_10_Image_0003.jpg',
    'page_11_Image_0002.jpg',
    'page_11_Image_0003.jpg',
    'page_12_Image_0002.jpg',
    'page_12_Image_0003.jpg',
    'page_13_Image_0002.jpg',
    'page_13_Image_0003.jpg',
    'page_14_Image_0002.jpg',
    'page_14_Image_0003.jpg',
    'page_15_Image_0002.jpg',
    'page_15_Image_0003.jpg',
    'page_15_Image_0004.jpg',
    'page_16_Image_0002.jpg',
    'page_16_Image_0003.jpg',
    'page_16_Image_0004.jpg',
    'page_16_Image_0005.jpg',
    'page_17_Image_0002.jpg',
    'page_17_Image_0003.jpg',
    'page_17_Image_0004.jpg',
    'page_18_Image_0002.jpg',
    'page_18_Image_0003.jpg',
    'page_19_Image_0002.jpg',
    'page_19_Image_0003.jpg',
    'page_20_Image_0002.jpg',
    'page_20_Image_0003.jpg',
    'page_20_Image_0004.jpg',
    'page_21_Image_0002.jpg',
    'page_21_Image_0003.jpg',
    'page_21_Image_0004.jpg',
    'page_21_Image_0005.jpg',
    'page_21_Image_0006.jpg',
    'page_22_Image_0002.jpg',
    'page_22_Image_0003.jpg',
    'page_22_Image_0004.jpg',
    'page_23_Image_0002.jpg',
    'page_23_Image_0003.jpg',
    'page_23_Image_0004.jpg',
    'page_23_Image_0005.jpg',
    'page_24_Image_0002.jpg',
    'page_24_Image_0003.jpg',
    'page_25_Image_0002.jpg',
    'page_25_Image_0003.jpg',
    'page_26_Image_0002.jpg',
    'page_26_Image_0003.jpg',
    'page_26_Image_0004.jpg',
];
