import { Gallery } from '../components/Gallery/Gallery';
import { galeriaImages } from '../Data/galeria-images';

export const GaleriaPage = () => {
    return (
        <div className="px-4 sm:px-12 lg:px-24 py-6">
            <div className="flex flex-col gap-2 justify-center items-center  py-8">
                <h1 className="text-center tracking-tight font-extrabold text-gray-900 text-3xl sm:text-4xl lg:text-5xl">
                    Galeria
                </h1>
                <p className="mb-4 tracking-tight text-lg text-red-700 font-bold uppercase">
                    Veja as fotos do Grupo
                </p>
                <Gallery images={galeriaImages} imagesBasePath="galeria/" />
            </div>
            <div className="flex flex-col gap-4 lg:gap-6 text-lg max-w-prose mx-auto  leading-8 filter sepia-200 "></div>
        </div>
    );
};
