// import imagemDeCapa from '../../assets/photo-capa.jpg';

import { Link } from 'react-router-dom';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface FrontPageProps {}

// eslint-disable-next-line no-empty-pattern
export const FrontPage = ({}: FrontPageProps) => {
    return (
        <div className="relative bg-gray-50 overflow-hidden">
            <div
                className="hidden sm:block sm:absolute sm:inset-y-0 sm:h-full sm:w-full"
                aria-hidden="true"
            >
                <div className="relative h-full max-w-7xl mx-auto"></div>
            </div>

            <div className="relative pt-6 pb-16 sm:pb-24">
                <main className="mt-16 mx-auto max-w-7xl px-4 sm:mt-24">
                    <div className="flex flex-col gap-3 text-center">
                        <img
                            className="mb-2"
                            src="./assets/photo-capa.jpg"
                            alt=""
                        />
                        <h1 className="text-8xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                            <span className="text-red-500">123º</span> GEMAR
                        </h1>
                        <p className="text-gray-800 text-4xl tracking-tight uppercase font-extrabold">
                            Almirante Saldanha
                        </p>
                        <p className="max-w-md mx-auto text-base text-gray-600 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
                            O 123° Grupo Escoteiro do Mar Almirante Saldanha,
                            foi fundado em 15 de julho de 1962 pelo então
                            Comodoro Carlos Borba (escoteiro número 1 do 123°
                            GEMar) para servir a família naval e a comunidade
                            local.
                        </p>
                        <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
                            <div className="rounded-md shadow">
                                <Link
                                    to="/contato"
                                    className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-red-500 hover:bg-red-600 md:py-4 md:text-lg md:px-10"
                                >
                                    Fale Conosco
                                </Link>
                            </div>
                            <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3">
                                <Link
                                    to="/historia"
                                    className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-red-500 bg-white hover:bg-gray-50 md:py-4 md:text-lg md:px-10"
                                >
                                    História
                                </Link>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
};
